<template>
  <div class="connect-fix-right">
    <div class="connect-data">
      <div class="connect-icon d-flex justify-content-evenly">
        <a href="https://www.facebook.com/%E8%88%88%E5%9F%8E%E5%9C%8B%E9%9A%9B-103463618524904" target="_blank">
          <img src="images/connect-icon1.svg" alt="">
        </a>
        <a href="https://line.me/R/ti/p/@576jxkha" target="_blank">
          <img src="images/connect-icon6.svg" alt="">
        </a>
        <a href="https://g.page/xincity?share" target="_blank">
          <img src="images/connect-icon2.svg" alt="">
        </a>
      </div>

      <img src="images/connect-text.svg" alt="" />
    </div>

    <div class="connect-bt">
      <img src="images/connect-bt.svg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "SideComponents",
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>