<template>
    <nav class="navbar navbar-expand-sm  fixed-top">

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <router-link :to="{name:'index'}" class="navbar-brand">
            <img src="images/logo-nav.svg">
        </router-link>

        <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav">
                <li> <router-link :to="{name:'news'}" title="小城新聞">小城新聞</router-link></li>
                <li> <router-link :to="{name:'hot-issue'}" title="熱線建案">熱線建案</router-link></li>
                <li> <router-link :to="{name:'performance'}" title="興城業績">興城業績</router-link></li>
                <li> <router-link :to="{name:'house-notes'}" title="買房筆記">買房筆記</router-link></li>
                <li> <router-link :to="{name:'land-service'}" title="土地評估">土地評估</router-link></li>
                <li> <router-link :to="{name:'contact'}" title="客戶售服">客戶售服</router-link></li>
            </ul>
        </div>

    </nav>
</template>

<script>
import $ from 'jquery'

export default {
    name: 'NavbarComponents',
    data() {
        return {

        }
    },
    methods: {

    },
    computed: {

    },
    mounted() {
        
        //nav按鈕往下
        $(".navbar-toggler-icon").click(function () {
            $(this).toggleClass("open");
        });

        //按下選單收合
        $(".navbar-nav>li").click(function () {
            $(".navbar-collapse").removeClass("show");
            $(".navbar-toggler-icon").removeClass("open");
        });
    },
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.router-link-exact-active{
    color:#d0121b;
}
.router-link-active{
    color:#d0121b;
}
</style>
