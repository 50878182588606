import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 表單寄信到E-MAIL
// import { VueEmailPlugin } from 'vue-email'


// 匯入 vee-validate 主套件-------------------------------------------------------
import {Field, Form, ErrorMessage, defineRule, configure,} from 'vee-validate';
// 匯入 vee-validate 相關規則
import { required, email, min } from '@vee-validate/rules';
// 匯入多國語系的功能
import { localize, setLocale } from '@vee-validate/i18n';
// 匯入繁體中文語系檔案
import zhTW from '@vee-validate/i18n/dist/locale/zh_TW.json';


import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/database'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'

const firebaseConfig = {
    apiKey: "AIzaSyASnNkyd89WisHi6lpuHeRmSUSyJxNdSS4",
    authDomain: "xincity0605-star.firebaseapp.com",
    databaseURL: "https://xincity0605-star-default-rtdb.firebaseio.com",
    projectId: "xincity0605-star",
    storageBucket: "xincity0605-star.appspot.com",
    messagingSenderId: "1072121115547",
    appId: "1:1072121115547:web:03c1facf3f14bd8502273e"
};

firebase.initializeApp(firebaseConfig);


// 定義驗證規則----------------------------
defineRule('required', required);
defineRule('email', email);
defineRule('min', min);
// 設定 vee-validate 全域規則
configure({
    generateMessage: localize({ zh_TW: zhTW }), // 載入繁體中文語系
    validateOnInput: true, // 當輸入任何內容直接進行驗證
});
// 設定預設語系
setLocale('zh_TW');
//------------------------------------------------------------------------------
// 表單寄信到E-MAIL
// app.use(VueEmailPlugin)

const app=createApp(App).use(store).use(router).use(firebase)

// 註冊 vee-validate 三個全域元件
app.component('VForm', Form);
app.component('VField', Field);
app.component('ErrorMessage', ErrorMessage);


app.mount('#app')
