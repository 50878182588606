<template>

  <NavbarComponents />

  <router-view/>

  <SideComponents />
  <FooterComponents />

</template>


<script>
import NavbarComponents from "@/components/NavbarComponents.vue";
import SideComponents from "@/components/SideComponents.vue";
import FooterComponents from "@/components/FooterComponents.vue";

export default {
  name: "HomeView",
  components: {
    NavbarComponents,
    SideComponents,
    FooterComponents
  },
};
</script>

<style scoped>
.navbar{
  background-color: black;
}
</style>
